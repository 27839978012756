import Vue from 'vue'
import Vuex from 'vuex'
// 由于vuex保存在内存中，所以刷新网页值会消失，初始化(无法做到本地持久化)
// 导入一个可以本地持久化的插件 vuex-persistedstate@3.2.1 配合vue2使用
import createPersistedState from 'vuex-persistedstate'
import { getUserInfoAPI } from '@/api/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '', // 用户token
    userinfo: {} // 定义用户信息对象
  },
  getters: {
    nickname: state => state.userinfo.nickname, // 昵称
    username: state => state.userinfo.username, // 用户名
    user_pic: state => state.userinfo.user_pic // 头像
  },
  mutations: {
    // 保存token
    updataToken (state, val) {
      state.token = val
    },
    // 保存用户信息
    updateUserInfo (state, val) {
      state.userinfo = val
    }
  },
  actions: {
    // 请求-》用户信息
    async getUserInfoActions (store) {
      const res = await getUserInfoAPI()
      store.commit('updateUserInfo', res.data.data)
    }
  },
  modules: {
  },
  // 配置为vuex的插件
  plugins: [createPersistedState()]
})
