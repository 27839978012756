// 封装的是具体的接口请求方法
// 注意：每个方法只请求一个url地址
import request from '@/utils/request'

// import $store from '@/store/index'

// 原地是promise对象 （内部包含原生ajax请求）
// return这个promise对象到逻辑页面，去那边拿结果
/*
  注册接口
*/
export function registerAPI (data) {
  return request({
    url: '/api/reguser',
    method: 'POST',
    // axios传参params,data
    // params的对象参数名和值，axios源码会把参数和值，拼接在url?后面给后台(query查询字符串)
    // data的对象参数名和值，axios源码会把参数和值，拼接在请求体里，(body参数)
    data
  })
}

/*
  登录
*/
export function loginAPI (data) {
  return request({
    url: '/api/login',
    method: 'POST',
    data
  })
}

/*
  获取用户信息接口
*/
export function getUserInfoAPI () {
  return request({
    url: '/my/userinfo',
    method: 'get'
    // 已经封装到request请求拦截器里面
    // 传参给后台，params(查询字符串query), data（请求体body） ， headers(请求头)
    // headers: {
    //   // this.$store.state.token 这里的this不是组件对象，所以拿不到store对象无法获取token
    //   Authorization: $store.state.token
    // }
  })
}

/*
  获取侧边栏类型
*/
export function getActesAPI () {
  return request({
    url: '/my/artcate/actes',
    method: 'get'
    // headers: {
    //   Authorization: $store.state.token
    // }
  })
}

/*
  更改用户信息
*/
export function getUserInfoDataAPI (data) {
  return request({
    url: '/my/userinfo',
    method: 'post',
    data
  })
}

/*
  更新用户头像
*/
export function getUserAvatarAPI (data) {
  return request({
    url: '/my/update/avatar',
    method: 'post',
    data
  })
}
/*
  更改用户密码
*/
export function getUserPasswordAPI (data) {
  return request({
    url: '/my/updatepwd',
    method: 'post',
    data
  })
}
/*
  获取文章分类数据
*/
export function getCategoryAPI () {
  return request({
    url: '/my/artcate/category',
    method: 'get'
  })
}
/*
  添加文章分类数据
*/
export function addArtCateaAPI (data) {
  return request({
    url: '/my/artcate/addcates',
    method: 'post',
    data
  })
}
/*
  修改文章分类数据
*/
export function upDataArtCateaAPI (data) {
  return request({
    url: '/my/artcate/updatecate',
    method: 'post',
    data
  })
}
// 删除文章分类数据
export function deleteCategoryAPI (params) {
  return request({
    url: `/my/artcate/deletecate/${params}`,
    method: 'get'
  })
}
/**
 * 文章列表，发布文章
 * @param {*} fd FormData 表单数据对象
 * @returns Promise对象
*/
export function pubArticleAPI (fd) {
  return request({
    url: '/my/article/add',
    method: 'post',
    data: fd
    // {} 如果是一个普通对象，axios会把他转成JSON字符串在请求体里交给后台
    // 这个接口要求请求体里时FormData类型(表单数据)，携带文件给后台
  })
}
/**
* 文章列表,获取文章信息
* @param {*} {title:文章名称，cate_id：文章分类id，state：发布状态，pageCurrent：页数，pageSize：条数}
* @returns Promise对象
*/
export function getpubArticleAPI (data) {
  return request({
    url: '/my/article/list',
    method: 'post',
    data
  })
}

/**
 * 文章列表，获取文章详情
 * @param {id:'文章id'}
 * @returns Promise对象
*/
export function getArticDetleListAPI (params) {
  return request({
    url: '/my/article/info',
    method: 'get',
    params
  })
}
/**
 * 文章列表，删除文章详情
 * @param {id:'文章id'}
 * @returns Promise对象
 * */
export function getArticDeletetleList (params) {
  return request({
    url: '/my/article/delete',
    method: 'get',
    params
  })
}
