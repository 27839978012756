<template>
  <div id="app">
    <!-- 一级对象组件挂载点 -->
    <router-view></router-view>
  </div>
</template>
<script>
// 为什么用大括号接受接口。有些其他的导入时候却没有
// 原因：这个接口导出的时候，是命名导出，所以我们应该命名导入

export default {
  data () {
    return {

    }
  },
  created () {

  },
  methods: {

  }
}
</script>
<style lang="less" scoped>
#app{
  width: 100%;
  height: 100%;
}
</style>
