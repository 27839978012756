import Vue from 'vue'
import VueRouter from 'vue-router'
import $store from '@/store/index'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/layout'),
    redirect: '/home', // 重定向 可以匹配二级路由
    children: [
      // 侧边栏导航，点击切换路由地址，路由地址靠数据请求回来铺设上去
      // 所以我们的路由规则要配合它保持一致
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/home')
      },
      {
        path: 'userInfo',
        component: () => import('@/views/user/userInfo')
      },
      {
        path: 'userAvatar',
        component: () => import('@/views/user/userAvatar')
      },
      {
        path: 'userPwd',
        component: () => import('@/views/user/userPwd')
      },  
      {
        path: 'category',
        component: () => import('@/views/article/category')
      },
      {
        path: 'artList',
        component: () => import('@/views/article/artList')
      },
      // {
      //   path: 'timePlan',
      //   component: () => import('@/views/timePlan/index')
      // },

    ]
  },
  {
    path: '/reg',
    name: 'reg',
    component: () => import('@/views/register/index.vue')
    // webpack提供的import函数来路由懒加载导入组件
    // 路由懒加载：就是页面路由路径切换到/reg,才去加载对应组件代码
    // 好处：加载体积更小，打开更快
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/layout',
    name: 'layout',
    component: () => import('@/views/layout/index.vue')
  },
  // 实现Activiti 在线流程图编辑器
  {
    path: '/activiti',
    name: 'activiti',
    component: () => import('@/views/activiti/activitiBpmn.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

// 准备白名单
const whiteList = ['/login', '/reg'] // 白名单
// ('无需登录可以访问的路由地址')

// 全局前置路由守卫 在登录路由跳转前，先调用
// 知识点1:浏览器第一次打开项目页面，会触发一次全局前置路由守卫
// 知识点2：有token证明登录。无则没登录
// 知识点3：next()如果强制切换路由地址，会再次走路由守卫再次去匹配路由表
router.beforeEach((to, from, next) => {
  const token = $store.state.token
  if (token) {
    // 登录
    if (!$store.state.userinfo.username) {
      // 有token ,但是没有用户信息，才会请求用户信息保存到vuex中
      // 调用actions内容请求数据
      // !$store.state.userinfo.username 防止vuex里已经有数据重复调用
      $store.dispatch('getUserInfoActions')
    }
    next()
  } else {
    // 未登录
    // 数组.includes(), 作用:判断值是否在数组中出现过，出现过原地返回true
    if (whiteList.includes(to.path)) {
      // 未登录，可以访问路径地址，则放行(路由的全局守卫不会再次触发了，而是匹配路由表接口，让组件挂载)、
      next()
    } else {
      // next()强制切换到登录页上
      next('/login')
    }
  }
})

export default router
