// 基于axios封装，网络请求函数
import axios from 'axios'
import $store from '@/store'
import router from '@/router'
import { Message } from 'element-ui'

// export const baseURL = 'http://127.0.0.1:3000' // 接口和图片地址 命名导出
export const baseURL = 'http://139.224.67.115:3001' // 接口和图片地址 命名导出 (服务端)

// 用axios.create() 方法创建带配置项的自定义的函数
// 基地址， service请求的时候，地址baseURL+url,然后去请求后台
const service = axios.create({
  baseURL: baseURL
})

// 请求拦截器
// api每次调用request都会先去请求拦截器
service.interceptors.request.use(function (config) {
  // config配置对象(要请求后台的参数都在这个对象上)
  // 在请求拦截之前响应一次返回给axios内源码，这个return交给axios源码内，根据配置项发起请求

  // 在发起时，同一携带请求头Authorization和token值
  // 判断,登录注册页面，vuex无token,而且登录注册无需携带token(其他页面需要)
  if ($store.state.token) {
    config.headers.Authorization = $store.state.token
  }

  // console.log(config)
  return config
}, function (error) {
  // 请求发起之前的代码如果有异常，会进入这里
  // 返回一个拒接状态的Promise对象(axios留在原地的Promise对象状态就为失败结果为error变量值)
  // 此函数类似于catch函数里的return
  // 口诀:return非Promise对象值，会作为成功的结果，返回给下一个Promise对象(axios留在原地)
  // 口诀:returnPromise对象，这个Promise对象状态，返回给下一个Promise对象
  // Promise.reject() 原地留下一个新的Promise对象(状态为失败) 它是Promise的类方法reject()
  return Promise.reject(error)
  // 等同于
  /**
  return new Promise((resolve,reject)=>{
    reject(error)
  })
  */
})

// 响应拦截器
service.interceptors.response.use(function (response) {
  // 当响应的状态码为2xx或3xx时 触发成功的回调，返回成功的结果
  // return到axios原地Promise对象,作为成功的结果
  return response
}, function (error) {
  //  当响应的状态码是为4xx或5xx时 触发失败的回调，返回失败的结果
  // return到axios原地Promise对象,作为拒接的状态(如果那边用try+catch或catch函数捕获，可以捕获到我们传递过去的这个error变量)
  console.log(error)
  if (error.response.status === 401) {
    // 本次响应的token过期
    // 清除vuex里面的一切，然后回到登录页 (被动退出登录状态)
    $store.commit('updataToken', '')
    $store.commit('updateUserInfo', {})

    router.push('/login')

    Message.error('用户身份已过期！！！')
  }
  return Promise.reject(error)
})

// 导出axios自定义函数
export default service
