// mian.js写太多内容，容易混乱，根据功能单独放到文件夹中
import Vue from 'vue'
import ELEMENT from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor) // 此方法会在Vue.component注册一个quillEditor名字的全局组件
Vue.use(ELEMENT)
