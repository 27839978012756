import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入全局样式
import '@/assets/global.less'
import '@/elementUI/index' // 让elementui注册的代码参与打包，才能在页面上运行并注册
import dayjs from 'dayjs'
import wschedule from '@/components/wschedule'
Vue.component('wschedule', wschedule)

// 基于dayjs封装格式化事件（任意组件可以使用$formatDate）
Vue.prototype.$formatDate = (dataObj) => {
  // 借助dayjs内置的方法把日期对象格式化成指定的格式，并把格式化好的字符串返回到调用处
  return dayjs(dataObj).format('YYYY-MM-DD HH:mm:ss')
}
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// 打包相关的问题:
// 问题:打开了index.html 出现了404问题
// 原因：
// 默认打包，index.html引入其他打包的文件使用的是 绝对路径
// 地址是以/开头·(要找到当前index.htm1打开时所在服务器的根地址(文件夹))
// 就得确保你的vscode+liveserver插件打开时，vscode根目录得直接是dist文件夹
// 你如果把dist文件夹交给后台运维工程师部署到服务器上，dist下内容就得在服务器根目录才行
// 但是服务器一般会有多个项目，不让你用根目录直接放一个项目，很乱
// 解决: webpack让你写一个配置项publicPath (控制index.htm1引入其他资源前缀地址)
// vue.config.js (脚手架配置文件，webpack配置文件)
// publicPath: 默认值 '/' 确保开发环境下，是这个值，因为开发服务器会把所有打包在内存里而且作
// 服务器的根目录文件夹,绝对地址
// 环境准备上线这个时候，就得用相对地址，publicPath './'
// 路径不已 '/' 开头 则就是以 './'
