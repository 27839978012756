<template>
    <div class="course-week">
      <div class="week-top">
        <div class="week-btn-wrap">
          <span @click="getLastWeek">上周</span>
          <span @click="getCurWeek">本周</span>
          <span @click="getNextWeek">下周</span>
        </div>
        <span class="w-today-date"> {{ todayDate }}</span>
        <div class="w-choose-status">
          <div v-for="(sta,index) in cardStatus" :key="index">
            <span class="square" :style="{background:sta.color}"></span>
            <span class="title">{{ sta.title }}</span>
          </div>
        </div>
      </div>
      <div class="week-table">
        <div class="table-header">
          <div class="table-week">
            <template v-for="(item,index) of weeks">
              <span class="w-first" v-if="index===0" :key="index">{{ item }}</span>
              <span v-else :key="index">{{ item }}</span>
            </template>
          </div>
          <div class="w-table-date">
            <template v-for="(item,index) of months">
              <span class="w-first" v-if="index===0" :key="index">
              </span>
              <template v-else>
                <span :key="index" class="w-day-item" :class="{'w-isCurDate':item&&item.isCurDate}">
                  {{ `${item && item.isCurDate ? item && item.showDate + '(今天)' || '' : item && item.showDate || ''}` }}
                </span>
              </template>
            </template>
          </div>
        </div>
        <div class="w-time-period-list">
          <ul class="w-time-period-row">
            <!--循环时段，看时段有多少个-->
            <template v-if="planList.length>0">
              <li class="w-time-period-col" v-for="(period,p_index) in planList"
                  :key="`period${p_index}`">
                <!--第一列显示时段-->
                <div class="w-time-period"> {{ period.timePeriod }}</div>
                <!-- 后面显示周一到周日的计划-->
                <div class="w-row-day">
                  <!-- 循环显示每周的日期-->
                  <template v-for="(month,m_index) of months">
                    <!-- v-if="month" 去除数据处理的时候移除数组第一个为empty的问题-->
                    <div v-if="month" :key="`month${m_index}`" class="w-things" @click="handleCardDetail(month,period)">
                      <!-- 循环每个时间段的计划-->
                      <template v-for="(card,t_index) of period.schedule">
                        <template v-for="(single,sIndex) in card[month.date]">
   
                          <template v-if="!card.isExpend">
                            <div v-if="single.date===month.date&&sIndex<2"
                                 :key="`thing${sIndex}`"
                                 class="w-thing-item"
                                 @click="handleDetail(single)"
                                 :style="{background: cardStatus[single.status].color}">
                              <slot name="thing" :row="single"></slot>
                            </div>
                          </template>
                          <template v-if="card.isExpend">
                            <div v-if="single.date===month.date"
                                 :key="`thing${sIndex}`"
                                 class="w-thing-item"
                                 @click="handleDetail(single)"
                                 :style="{background: cardStatus[single.status].color}">
                              <slot name="thing" :row="single"></slot>
                            </div>
                          </template>
                          <div class="w_expand"
                               v-if="card[month.date].length>2&&(card[month.date].length-1)===sIndex&&!card.isExpend&&single.date===month.date"
                               :key="sIndex"
                               @click="handleExpand(card)">展开
                          </div>
                          <div class="w_shrink"
                               v-if="card[month.date].length>2&&(card[month.date].length-1)===sIndex&&card.isExpend&&single.date===month.date"
                               :key="sIndex"
                               @click="handleExpand(card)">收缩
                          </div>
                        </template>
   
                      </template>
   
                    </div>
                  </template>
                </div>
              </li>
            </template>
            <div class="w-noMore" v-else><span>暂无数据</span></div>
          </ul>
        </div>
      </div>
    </div>
  </template>

 <script>
import { formatDate, getCurDay } from '../utils/MyTools.js';

export default {
  name: 'WSchedule',
  props: {
    planList: {
      type: Array,
      default: []
    },
    cardStatus: {
      type: Object,
      default: () => {
        return {
          1: {
            title: '已过期',
            color: '#9CADADB7'
          },
          2: {
            title: '进行中',
            color: '#FF6200'
          },
          3: {
            title: '未开始',
            color: '#3291F8'
          },
        }
      }
    },
    isFirstDayOfMondayOrSunday: {
      type: Number,
      default: 1,
    }
  },
  data () {
    return {
      weeks: [
        '时段', '周一', '周二', '周三', '周四', '周五', '周六', '周日',
      ],
      todayDate: '',
      months: [],
      curDate: '',
      nowDate: new Date(),
    }
  },
  watch: {
    isFirstDayOfMondayOrSunday: {
      handler (val) {
        if (val > 1) {
          let arr = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
          const arr1 = arr.slice(val - 1)
          const arr2 = arr.slice(0, val - 1)
          this.weeks = ['时段', ...arr1, ...arr2]
        }
      },
      immediate: true
    }
  },
  mounted () {

    this.getCurWeek()
  },
  methods: {
    //展开与缩放操作
    handleExpand (row) {
      row.isExpend = !row.isExpend
    },
    /**
     * 获取 时间
     * @param time
     */
    getWeek (time) {
      this.curDate = new Date(time)
      //当前是周几
      const whichDay = time.getDay()
      let num = 0
      if (this.isFirstDayOfMondayOrSunday <= whichDay) {
        num = this.isFirstDayOfMondayOrSunday
      } else {
        num = this.isFirstDayOfMondayOrSunday - 7
      }
      const weekDay = time.getDay() - num
      time = this.addDate(time, weekDay * -1)
      for (let i = 0; i < 7; i++) {
        const { year, month, day } = formatDate(i === 0 ? time : this.addDate(time, 1))
        this.months.push({
          date: `${year}-${month}-${day}`,
          showDate: `${month}-${day}`,
          timestamp: new Date(`${year}-${month}-${day}`).getTime()
        })
      }
      this.months.sort((a, b) => a.timestamp - b.timestamp)
      delete this.months[0]
      this.todayDate = `${this.months[1].date} ~ ${this.months[this.months.length - 1].date}`
    },
    /**
     * 处理日期
     * @param date
     * @param n
     * @returns {*}
     */
    addDate (date, n) {
      date.setDate(date.getDate() + n)
      return date
    },
    /**
     * 上周
     */
    getLastWeek () {
      const date = this.addDate(this.curDate, -7),
          { year, month, day } = formatDate(date),
          dateObj = {
            date: `${year}-${month}-${day}`,
            timestamp: new Date(`${year}-${month}-${day}`).getTime()
          }
      this.dealDate(date)
      this.$emit('changeWeek', dateObj)
    },
    /**
     * 本周
     */
    getCurWeek () {
      const { year, month, day } = formatDate(new Date()),
          dateObj = {
            date: `${year}-${month}-${day}`,
            timestamp: new Date(`${year}-${month}-${day}`).getTime()
          }
      this.dealDate(new Date())
      this.$emit('changeWeek', dateObj)
    },
    /**
     * 下周
     */
    getNextWeek () {
      const date = this.addDate(this.curDate, 7),
          { year, month, day } = formatDate(date),
          dateObj = {
            date: `${year}-${month}-${day}`,
            timestamp: new Date(`${year}-${month}-${day}`).getTime()
          }
      this.dealDate(date)
      this.$emit('changeWeek', dateObj)
    },
    /**
     * 显示当天日期状态
     * @param date
     */
    dealDate (date) {
      this.months = ['']
      this.getWeek(date)
      const curDate = getCurDay()
      this.months.forEach(item => {
        item.isCurDate = item.date === curDate
      })
    },
    /**
     * 点击卡片子内容查看详情
     * @param row
     */
    handleDetail (row) {
      this.$emit('handleDetail', row)
    },
    /**
     * 点击卡片查看全部内容
     * @param month
     * @param period
     */
    handleCardDetail (month, period) {
      this.$emit('handleCardDetail', { ...month, ...period })
    }
  }
}


 </script>
 